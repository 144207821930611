// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import ReactiveSearch from "@appbaseio/reactivesearch-vue";
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import attemptToAuthoriseTokens from './helpers/authorise-tokens'
import './registerServiceWorker'
import { VueMasonryPlugin } from "vue-masonry";
import { VueMaskDirective } from 'v-mask'
import NProgress from 'vue-nprogress'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import './assets/css/custom.scss'

Vue.use(vueAwesomeCountdown, 'vac')
Vue.use(NProgress)
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import VueRouterUserRoles from "vue-router-user-roles";
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

import VueScrollactive from 'vue-scrollactive';

Vue.use(VueScrollactive);

const nprogress = new NProgress()

store.dispatch('auth/initAuth')

attemptToAuthoriseTokens()
Vue.use(VueRouterUserRoles, { router });
Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false
Vue.use(ReactiveSearch);
Vue.use(VueMasonryPlugin);



const user =  (localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser'))) || { role:"guest"} 


Vue.prototype.$user.set(user);                 

new Vue({
  nprogress,
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')



