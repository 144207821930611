import Vue from 'vue'
import VueI18n from 'vue-i18n'

import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'
import pt from 'vuetify/lib/locale/pt'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en/labels.json'),
    ...require('@/locales/en/errors.json'),
    ...require('@/locales/en/drugs_sections.json'),
    ...require('@/locales/en/drugnames.json'),
    ...require('@/locales/en/atc.json'),
    ...require('@/locales/en/recomendations.json'),
    $vuetify: en,
  },
  es: {
    ...require('@/locales/es/labels.json'),
    ...require('@/locales/es/errors.json'),
    ...require('@/locales/es/drugs_sections.json'),
    ...require('@/locales/es/drugnames.json'),
    ...require('@/locales/es/atc.json'),
    ...require('@/locales/es/recomendations.json'),
    $vuetify: es,
  },
  po: {
    ...require('@/locales/po/labels.json'),
    ...require('@/locales/po/errors.json'),
    ...require('@/locales/po/drugs_sections.json'),
    ...require('@/locales/po/drugnames.json'),
    ...require('@/locales/po/atc.json'),
    ...require('@/locales/po/recomendations.json'),
    $vuetify: pt,
  },
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages,
})
