import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/Login"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "admin",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            },
          ]
        }
      },
      {
        name: "Register",
        path: "register",
        component: () => import("@/views/auth/Register"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "admin",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            },
          ]
        }
      },
      {
        name: "Recover",
        path: "recover",
        component: () => import("@/views/auth/Recover"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "admin",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            },
          ]
        }
      },
      {
        name: "ProRegister",
        path: "pro-register",
        component: () => import("@/views/auth/ProRegister"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "admin",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            },
          ]
        }
      },
      {
        name: "ConfirmationSent",
        path: "confirmation-sent",
        component: () => import("@/views/auth/ConfirmationSent"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "admin",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            },
          ]
        }
      },
      {
        name: "AccountSetup",
        path: "account-setup/:id",
        props: true,
        component: () => import("@/views/auth/AccountSetup"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "admin",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            },
          ]
        }
      }
    ]
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/app/Home"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/infopage",
    name: "InfoPage",
    component: () => import("@/views/app/InfoPage"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/subscription-plans",
    name: "SubscriptionPlans",
    component: () => import("@/views/app/SubscriptionPlans"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/app/UserAccount"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/genetic-profile",
    component: () => import("@/views/app/genetic_profile/Index"),
    children: [
      {
        name: "GeneticProfile",
        path: "",
        component: () => import("@/views/app/genetic_profile/GeneticProfile"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/explorer",
    component: () => import("@/views/app/explorer/Index"),
    children: [
      {
        name: "Explorer",
        path: "view/:app",
        props: true,
        component: () => import("@/views/app/explorer/Explorer"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            },
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
        
      },
      {
        name: "ApdxDrugClass",
        path: "drug-classes",
        component: () => import("@/views/app/explorer/ApdxDrugClass"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            },
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
        
      },
      {
        name: "ApdxDiseasesGenes",
        path: "diseases-genes",
        component: () => import("@/views/app/explorer/ApdxDiseasesGenes"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            },
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
        
      },
      {
        name: "Explorer.gene_detail",
        path: "genes/:id",
        props: true,
        component: () => import("@/views/app/explorer/GeneDetail"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            },
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            */
          ]
        }
      },
      {
        name: "Explorer.drug_detail",
        path: "drugs/:id",
        props: true,
        component: () => import("@/views/app/explorer/DrugDetail"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
      }
    ]
  },
  {
    path: "/pgx-results",
    component: () => import("@/views/app/pgx_results/Index"),
    children: [
      {
        name: "PGXResults",
        path: "",
        component: () => import("@/views/app/pgx_results/PGXResults"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/admin/:userId/genes-weights",
    component: () => import("@/views/app/admin/genes_weights/Index"),
    children: [
      // Dashboard
      {
        name: "GenesWeights",
        props: true,
        path: "",
        component: () => import("@/views/app/admin/genes_weights/GenesWeights"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/admin/:userId/genetic-profile",
    component: () => import("@/views/app/admin/genetic_profile/Index"),
    children: [
      {
        name: "AdminGeneticProfile",
        props: true,
        path: "",
        component: () =>
          import("@/views/app/admin/genetic_profile/GeneticProfile"),
          meta: {
            permissions: [
              {
                role: "guest",
                access: false,
                redirect: "Login"
              }
            ]
          }
      }
    ]
  },
  {
    path: "/admin/users",
    component: () => import("@/views/app/admin/users/Index"),
    children: [
      {
        name: "ListUsers",
        path: "",
        component: () => import("@/views/app/admin/users/ListUsers"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/admin/keys-manager",
    component: () => import("@/views/app/admin/keys_manager/Index"),
    children: [
      {
        name: "DataMatrixKeysManager",
        path: "data-matrix",
        component: () => import("@/views/app/admin/keys_manager/DataMatrixKeys"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      },
      {
        name: "GenotyperKeysManager",
        path: "genotyper",
        component: () => import("@/views/app/admin/keys_manager/GenotyperKeys"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "*",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/app/Error")
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;
