/* eslint-disable prefer-promise-reject-errors */
import auth from '@/gotrue.js'
import Vue from 'vue'

export default {
  strict: true,
  namespaced: true,

  state () {
    return {
      currentUser: null,
    }
  },
  getters: {
    loggedIn: state => !!state.currentUser,
    currentUser: state => JSON.parse(state.currentUser),
    netlifyUserLoggedIn: () => !!auth.currentUser(),
    currentNetlifyUser: () => auth.currentUser(),
    isAdmin: () => {
      return auth.currentUser() && auth.currentUser().app_metadata && auth.currentUser().app_metadata.roles && auth.currentUser().app_metadata.roles.includes("admin")
    },
    roles: state => {
      const currentUser = JSON.parse(state.currentUser)
      if (currentUser && currentUser.app_metadata && currentUser.app_metadata.roles ) {
        return currentUser.app_metadata.roles 
      }
      else {
        return []
      }
    }
  },
  mutations: {
    SET_CURRENT_USER (state, value) {
      state.currentUser = value

      const getMainRole = (currentUser) => {
        if (currentUser && currentUser.app_metadata && currentUser.app_metadata.roles ) {
          if (currentUser.app_metadata.roles.includes("ADMIN")) {
            currentUser.role = "admin"
          }
          else if (currentUser.app_metadata.roles.includes("TRIAL")) {
            currentUser.role = "trial"
          }
          else if (currentUser.app_metadata.roles.includes("UNPAID")) {
            currentUser.role = "unpaid"
          }
          else if (currentUser.app_metadata.roles.includes("ADVANCED")) {
            currentUser.role = "advanced"
          }
          else if (currentUser.app_metadata.roles.includes("PRO")) {
            currentUser.role = "pro"
          }
          else {
            currentUser.role = "basic"
          }
          return currentUser
        }
        else {
          return { role: "guest" }
        }
      }
      Vue.prototype.$user.set(getMainRole(JSON.parse(value)))
      localStorage.setItem('currentUser', JSON.stringify(getMainRole(JSON.parse(value))));
 
    },
    UNSET_CURRENT_USER (state) {
      Vue.prototype.$user.set({ role: "guest" })
      localStorage.setItem('currentUser', JSON.stringify({ role: "guest" }));  
      state.currentUser = null
    },
  },
  actions: {
    invokeSignupFunction (store, JWT) {
      return new Promise((resolve, reject) => {
        const URL = `https://${document.location.hostname}/.netlify/functions/identity-external-signup`
        fetch(URL, {
          method: 'POST',
          headers: {
            'cache-control': 'no-cache',
            Authorization: 'Bearer ' + JWT,
          },
        })
          .then(resp => resp.json())
          .then(data => {
            if (data.code >= 400) {
              reject(data.msg)
            }
            resolve(data)
          })
          .catch(error => {
            reject('error invoking signup function directly', error)
          })
      })
    },
    attemptLogin ({ commit, state }, credentials) {
      return new Promise((resolve, reject) => {
        auth.login(credentials.email, credentials.password, true)
          .then(response => {
            commit('SET_CURRENT_USER', JSON.stringify(response))
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    attemptSignup ({ state }, credentials) {
      return new Promise((resolve, reject) => {
        auth.signup(credentials.email, credentials.password, {
          MSC: credentials.MSC,
          type: credentials.type
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    attemptConfirmation ({ state }, token) {
      return new Promise((resolve, reject) => {
        auth.confirm(token)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    attemptLogout ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!auth.currentUser()){
          commit('UNSET_CURRENT_USER')
            resolve("ok")
        }
        else {
          auth.currentUser()
          .logout()
          .then(resp => {
            commit('UNSET_CURRENT_USER')
            resolve(resp)
          })
          .catch(error => {
            commit('UNSET_CURRENT_USER')
            reject(error)
          })
        }
        
      })
    },
    initAuth ({ commit, rootGetters }) {
      commit('app/SET_DEV_ENV', true, { root: true })
    },
    requestPasswordRecover ({ state }, email) {
      auth.requestPasswordRecovery(email)
        .then(response => alert('Recovery email sent', { response }))
        .catch(error => alert('Error sending recovery mail:', error))
    },
    attemptPasswordRecovery ({ state, commit }, token) {
      return new Promise((resolve, reject) => {
        auth.recover(token)
          .then(response => {
            console.log('Signing in user with recovery token')
            commit('SET_CURRENT_USER', response)
            resolve(response)
          })
          .catch(error => {
            console.error('Failed to verify recover token: %o', error)
            reject()
          })
      })
    },
    updateUserAccount ({ state }, userData) {
      return new Promise((resolve, reject) => {
        const user = auth.currentUser()
        user
          .update(userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
