<template>
  <div id="app-container">
    <nprogress-container></nprogress-container>
    <router-view />
    <splash-screen />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import SplashScreen from "@/components/mylogy/SplashScreen.vue";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";

export default {
  name: "App",
  components: { SplashScreen, NprogressContainer },
  data() {
    return {
      deferredPrompt: null
    };
  },
  created() {},
  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  },
  mounted() {
    this.$store.commit("app/SET_SITE_URL", process.env.VUE_APP_SITE_URL);
  }
};
</script>

<style>
@import "/src/assets/snipcart.css";

select[name="sort-options"] {
  display: none;
}

#nprogress .bar {
  background: #6ffcbc;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

.results a.active {
  border-style: solid;
    border-color: #4d2cb9;
    border-radius: 50%;
    width: 36px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #6ffcbc, 0 0 5px #6ffcbc;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

ul ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}
ul :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}
ul ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}
ul :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}
ul input::placeholder {
  color: transparent;
}


</style>
