export default {
  strict: false,
  namespaced: true,

  state() {
    return {
      genotype: {},
      pgxGenes: {},
      pgxDrugResults: [],
      model: { name: "FARMACOCINÉTICA + FARMACODINÁMICA", val: "mixed" },
      modelFamilies: {
        mixed: ["phase-i", "phase-ii", "transporter", "receptor", "pathogenic"],
        splitted: ["phase-i", "phase-ii", "transporter"]
      }
    };
  },
  getters: {
    getModel: state => {
      return state.model;
    },
    getPgx: state => {
      return Object.keys(state.pgxGenes).map(k => {
        const {
          activity,
          code,
          frecuency,
          gene,
          genotype,
          phenotype,
          recomendation,
          snp,
          snps_involved
        } = state.pgxGenes[k];

        return {
          activity,
          class: state.pgxGenes[k]["class"],
          code,
          gene: gene.toUpperCase(),
          genotype,
          phenotype,
          recomendation,
          snp,
          snpsInvolved: snps_involved
        };
      });
    },
    getGenotype: state => {
      return Object.keys(state.genotype).map(k => {
        const { gene_key, genotype, pgx_key, snp } = state.genotype[k];

        return { geneKey: gene_key, snp, pgxKey: pgx_key, genotype };
      });
    },
    getNotNeutralGenes: state => {
      return Object.keys(state.pgxGenes)
        .map(k => {
          const { code, gene } = state.pgxGenes[k];
          return { code, gene: gene.toLowerCase() };
        })
        .filter(g => {
          return (
            g.code != 2 &&
            state.modelFamilies[state.model.val].includes(g.class)
          );
        })
        .map(g => {
          return g.gene;
        });
    },
    getPGXResults: state => {
      const colors = ["grey", "grey", "green", "yellow", "orange", "red"];
      let pgxResults = {}
      Object.keys(state.pgxGenes).forEach(g=> {
        pgxResults[g] = {
          color: `https://mylogy.s3.filebase.com/${colors[state.pgxGenes[g].code]}.png`,
          legend: state.pgxGenes[g].phenotype,
          snp: state.pgxGenes[g].snp
        }
      })
      return pgxResults
    },
    getReportData: (state, getters) => {
      return {...{
        foto_portada:
          "https://mylogygenomics.com/wp-content/uploads/2020/11/Pildoras-fondo.jpg",
        logo_portada:
          "https://mk0euroespesnewu49pn.kinstacdn.com/wp-content/uploads/2020/07/logo-euroespes-web.png",
        "logo_portada-pequeno":
          "https://mylogygenomics.com/wp-content/uploads/2020/10/logo-mylogy.png",
        user: {
          name: "Nombre",
          surname: "del Paciente",
          id: "12345"
        },
        info: state.pgxDrugResults
      }, ...getters.getPGXResults}
    },
    areResultsLoaded: state => state.pgxGenes != {},
    getGeneResult: state => gene => {
      if (state.pgxGenes[gene]) {
        return state.pgxGenes[gene];
      }
      return {
        phenotype: "",
        code: 0,
        snp: "",
        activity: "",
        gene: false,
        recomendation: "",
        genotype: "",
        frequency: ""
      };
    },
    getDrugResult: state => drug => {
      const involved_genes = [
        ...new Set(
          drug.substrate_of.map(g => {
            return g.split("|")[0];
          })
        )
      ];
      const involved_genes_results = involved_genes
        .filter(g => {
          return (
            state.pgxGenes[g] &&
            state.modelFamilies[state.model.val].includes(
              state.pgxGenes[g].class
            )
          );
        })
        .map(g => {
          return state.pgxGenes[g];
        })
        .sort((a, b) => {
          if (a.code > b.code) {
            return -1;
          }
          if (a.code < b.code) {
            return 1;
          }
          return 0;
        });

      return (
        (involved_genes_results.length > 0 && involved_genes_results) || [
          { code: 0, recomendation: "no-interactions" }
        ]
      );
    }
  },
  mutations: {
    SET_GENOTYPE(state, value) {
      state.genotype = value;
    },
    SET_PGX_GENES(state, value) {
      state.pgxGenes = value;
    },
    SET_PGX_DRUG_RESULTS(state, value) {
      state.pgxDrugResults = value;
    },
    SET_MODEL(state, model) {
      state.model = model;
    }
  },
  actions: {
    setPGXDrugResults({ commit, getters }, payload) {
      const colors = [ "powderblue", "grey", "green", "yellow", "orange", "red"];
      const groupBy = (items, key) =>
        items
          .sort((a, b) => {
            if (a.atc < b.atc) {
              return -1;
            }
            if (a.atc > b.atc) {
              return 1;
            }
            return 0;
          })
          .reduce(
            (result, item) => ({
              ...result,
              [item[key]]: [...(result[item[key]] || []), item]
            }),
            {}
          );
      const drugResults = payload.map(d => {
        return {
          name: d._source.es,
          atc: d._source.atc_2_ES[0],
          color: `https://mylogy.s3.filebase.com/${
            colors[getters.getDrugResult(d._source)[0].code]
          }.png`
        };
      })
      const atcResults = Object.entries(groupBy(drugResults, "atc"));
      const drugResultsByATC = atcResults.map(e => {
        return {
          section: e[0].includes("ANTIADREN") ? "ANTIADRENÉRGICOS DE ACCIÓN CENTRAL" : e[0],
          green_legend: "Lenda verde",
          yellow_legend: "Lenda amarela",
          orange_legend: "Lenda laranxa",
          red_legend: "Lenda vermella",
          drug: e[1].sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        };
      }).filter(e => {
        return e.section != "undefined"
      }).sort((a, b) => {
        if (a.section < b.section) {
          return -1;
        }
        if (a.section > b.section) {
          return 1;
        }
        return 0;
      })

      commit("SET_PGX_DRUG_RESULTS", drugResultsByATC);
    },
    setGenotype({ commit, state }, payload) {
      let pgxGenotype = {};
      payload.data.forEach(genotype => {
        pgxGenotype[genotype["data"]["pgx_key"]] = genotype["data"];
      });
      commit("SET_GENOTYPE", pgxGenotype);
    },
    setPGXGenes({ commit, state }, payload) {
      let pgxGenes = {};
      const data = payload.data;
      payload.data.forEach(gene => {
        pgxGenes[gene["data"]["gene"]] = gene["data"];
      });
      commit("SET_PGX_GENES", pgxGenes);
    }
  }
};
