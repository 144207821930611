/*
Extract and validate tokens in the URL if they are present.
*/
import store from '../store'
import router from '../router'
import VuetifyDialog from 'vuetify-dialog'

function detectTokens () {
  const emailToken = detectEmailConfirmationToken()
  const recoveryToken = detectRecoveryToken()

  if (emailToken) {
    console.log('Detected email confirmation token', emailToken)
    confirmEmailToken(emailToken)
    return
  } else if (recoveryToken) {
    console.log('found recovery token', recoveryToken)
    confirmRecoveryToken(recoveryToken)
    return
  }

  console.log('No tokens detected in URL hash')
}

/**
 * Checks URL hash for `confirmation_token=` then extracts the token which proceeds.
 */
function detectEmailConfirmationToken () {
  try {
    // split the hash where it detects `confirmation_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
      'confirmation_token=',
    )[1]
    return token
  } catch (error) {
    console.error(
      'Something went wrong when trying to extract email confirmation email',
      error,
    )
    return null
  }
}

function detectRecoveryToken () {
  try {
    const token = decodeURIComponent(document.location.hash).split(
      'recovery_token=',
    )[1]
    return token
  } catch (error) {
    console.error(
      'Something went wrong when trying to extract email confirmation email',
      error,
    )
    return null
  }
}

function confirmEmailToken (token) {
  store
    .dispatch('auth/attemptConfirmation', token)
    .then(resp => {
      router.push({ name: 'AccountSetup', query: { email: resp.email}, params: { id: resp.id} })
    })
    .catch(error => {
      alert('Can\'t authorise your account right now. Please try again')
      console.error(error, 'Somethings gone wrong logging in')
    })
}

function confirmRecoveryToken (recoveryToken) {
  store
    .dispatch('auth/attemptPasswordRecovery', recoveryToken)
    .then(() => {
      router.push({name: 'Account'})
      alert('Account has been recovered. Update your password now')
    })
    .catch(() => {
      alert('Can\'t recover password')
    })
}

export default function () {
  detectTokens()
}
